"use client";

import {
    ForwardRefExoticComponent,
    RefAttributes,
    SyntheticEvent,
    VideoHTMLAttributes,
    forwardRef,
    useCallback,
    useRef,
    useState,
} from "react";
import Loading from "../loading/Loading";

type IProps = VideoHTMLAttributes<HTMLVideoElement> & {
    src: string;
    loopWithDelay?: boolean;
};

const DELAY = 3000;

const Video: ForwardRefExoticComponent<IProps & RefAttributes<HTMLVideoElement>> = forwardRef(
    (
        { src, muted = true, loopWithDelay, loop = !loopWithDelay, onEnded, onError, ...rest },
        ref,
    ) => {
        const [ready, setReady] = useState(false);
        const timeoutRef = useRef<NodeJS.Timeout | null>(null);

        const onLoadCallback = useCallback(() => {
            if (!ready) {
                setReady(true);
            }
        }, [ready]);

        const onEndedHandler = useCallback(
            (e: SyntheticEvent<HTMLVideoElement>) => {
                if (loopWithDelay) {
                    const video = e.currentTarget;

                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() => {
                        video.play();
                    }, DELAY);
                }

                onEnded?.(e);
            },
            [loopWithDelay, onEnded],
        );

        if (!src.includes("mp4")) {
            return null;
        }

        return (
            <>
                {!ready && <Loading />}
                <video
                    ref={ref}
                    src={src}
                    autoPlay
                    playsInline
                    muted={muted}
                    loop={loop}
                    onLoadedData={onLoadCallback}
                    onPlay={onLoadCallback}
                    onEnded={onEndedHandler}
                    onError={onError}
                    {...rest}></video>
            </>
        );
    },
);

export default Video;
