"use client";

import classNames from "classnames";
import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import { DropdownOption } from "@finbackoffice/site-core";
import Button from "../button/Button";
import FadeInAnimation from "../fade-in/FadeInAnimation";
import Loading from "../loading/Loading";
import Modal, { IModalForwardRefProps } from "../modal/Modal";
import Translate from "../translate/Translate";
import { Svg } from "../svg/Svg";
import styles from "./custom-select.module.sass";

type IProps = {
    data: DropdownOption[];
    selected: string | string[];
    onClickHandler: (value: string) => void;
    onCheckboxlClick: (value: string) => void;
    multiple: boolean;
    renderItem: ((item: DropdownOption) => JSX.Element) | undefined;
};

const List: FC<IProps> = ({
    data,
    selected,
    onClickHandler,
    onCheckboxlClick,
    multiple,
    renderItem,
}) => (
    <FadeInAnimation>
        <ul>
            {data.map((item) => (
                <li
                    key={item.value}
                    value={item.value}
                    className={classNames(
                        (multiple
                            ? selected.indexOf(item.value) !== -1
                            : selected === item.value) && "selected",
                    )}
                    onClick={() => onClickHandler(item.value)}
                    role="presentation">
                    {multiple && item.value && (
                        <label
                            htmlFor={`checkbox_${item.value}`}
                            className={classNames(
                                "ui-checkbox",
                                styles.checkbox,
                                selected.indexOf(item.value) !== -1 && "checked",
                            )}>
                            <input type="checkbox" id={`checkbox_${item.value}`} />
                            <i
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onCheckboxlClick(item.value);
                                }}
                            />
                        </label>
                    )}
                    {item.iconClass && <i className={item.iconClass} />}
                    {item.svgSrc && <Svg src={item.svgSrc} wrapper="span" className="svg-icon" />}
                    {renderItem ? (
                        renderItem(item)
                    ) : (
                        <Translate
                            tid={typeof item.label === "string" ? item.label : item.label.tid}
                            namespace={
                                typeof item.label !== "string"
                                    ? item.label.namespace
                                    : TranslationScopes.Common
                            }
                        />
                    )}
                </li>
            ))}
        </ul>
    </FadeInAnimation>
);

export type IOnChangeValue<T extends string, P extends boolean> = P extends true ? T[] : T;

type ICustomSelectProps<T extends string, P extends boolean = false> = {
    variant?: string;
    selected: P extends true ? T[] : T;
    data: DropdownOption[];
    styleClass?: string;
    onChange: (val: IOnChangeValue<T, P>) => void;
    loading?: boolean;
    placeholder?: string;
    type?: string;
    multiple?: P;
    renderItem?: (item: DropdownOption) => JSX.Element;
    testId?: string;
};

const CustomSelect = <T extends string, P extends boolean = false>({
    variant = "modal",
    selected,
    onChange,
    data,
    type,
    placeholder,
    styleClass,
    multiple,
    loading,
    renderItem,
    testId = "",
}: ICustomSelectProps<T, P>) => {
    const modalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const [opened, setOpened] = useState<boolean>(false);
    const dropdownRef: RefObject<HTMLDivElement> = useRef(null);

    useEffect(() => {
        const outsideClickHandler = (event: any) => {
            if (dropdownRef && !dropdownRef.current?.contains(event.target)) {
                setOpened(false);
            }
        };

        if (variant === "dropdown") {
            if (opened) {
                document.addEventListener("mousedown", outsideClickHandler);
            } else {
                document.removeEventListener("mousedown", outsideClickHandler);
            }
        }
        return () => {
            if (variant === "dropdown") {
                document.removeEventListener("mousedown", outsideClickHandler);
            }
        };
    }, [opened, variant]);

    const onClickHandler = (value: string) => {
        if (multiple) {
            onChange([value] as IOnChangeValue<T, P>);
        } else {
            onChange(value as IOnChangeValue<T, P>);
        }

        if (variant === "modal") {
            modalRef.current?.close();
        } else if (variant === "dropdown") {
            setOpened(false);
        }
    };

    const onCheckboxlClick = (val: string) => {
        const index: number = Array.isArray(selected) ? selected?.indexOf(val as T) : -1;
        if (index !== -1) {
            const tempSelected: string[] = [...selected];
            tempSelected.splice(index, 1);

            onChange(tempSelected as IOnChangeValue<T, P>);
        } else if (selected[0]) {
            onChange([...selected, val] as IOnChangeValue<T, P>);
        } else {
            onChange([val] as IOnChangeValue<T, P>);
        }
    };

    const open = useCallback(() => {
        if (variant === "modal") {
            modalRef.current?.open();
        } else {
            setOpened(!opened);
        }
    }, [opened, variant]);

    const handleOpen = useCallback(() => {
        if (!loading) {
            open();
        }
    }, [loading, open]);

    const selectedValue = data?.find((item) =>
        multiple ? item.value === selected[0] : item.value === selected,
    );

    return (
        <>
            <div
                className={classNames(styleClass, styles.container)}
                ref={dropdownRef}
                data-testid={testId}>
                <Button
                    type="button"
                    variant="custom"
                    className={styles.multipleSelector}
                    onClick={handleOpen}>
                    {loading ? (
                        <Loading wrapperClassName={styles.dropdownLoader} />
                    ) : selectedValue ? (
                        <>
                            <span>
                                {selectedValue.iconClass && (
                                    <i className={selectedValue.iconClass} />
                                )}
                                {selectedValue.svgSrc && (
                                    <Svg
                                        src={selectedValue.svgSrc}
                                        wrapper="span"
                                        className="svg-icon"
                                    />
                                )}
                                <span className={styles.value}>
                                    <Translate
                                        tid={
                                            typeof selectedValue.label === "string"
                                                ? selectedValue.label
                                                : selectedValue.label.tid
                                        }
                                        namespace={
                                            typeof selectedValue.label !== "string"
                                                ? selectedValue.label.namespace
                                                : TranslationScopes.Common
                                        }
                                    />
                                </span>
                            </span>
                            {multiple && selected.length > 1 && <i>{`+${selected.length - 1}`}</i>}
                        </>
                    ) : (
                        <span className={styles.placeholder}>
                            {!placeholder && "Select an Option"}
                            {placeholder && <Translate tid={placeholder} />}
                        </span>
                    )}
                </Button>
                {variant === "dropdown" && opened && (
                    <div className={styles.dropdown}>
                        <List
                            data={data}
                            selected={selected}
                            onClickHandler={onClickHandler}
                            onCheckboxlClick={onCheckboxlClick}
                            multiple={multiple || false}
                            renderItem={renderItem}
                        />
                    </div>
                )}
            </div>
            {variant === "modal" && type && (
                <Modal
                    ref={modalRef}
                    type={type}
                    animateVariant="opacity"
                    styleClass={styles.dropdownModal}
                    priority>
                    <List
                        data={data}
                        selected={selected}
                        onClickHandler={onClickHandler}
                        onCheckboxlClick={onCheckboxlClick}
                        multiple={multiple || false}
                        renderItem={renderItem}
                    />
                </Modal>
            )}
        </>
    );
};

export default CustomSelect;
