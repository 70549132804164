"use client";

import classNames from "classnames";
import {
    SyntheticEvent,
    useEffect,
    useRef,
    useCallback,
    useState,
    HTMLInputTypeAttribute,
    InputHTMLAttributes,
    forwardRef,
    Ref,
    ChangeEvent,
} from "react";
import { FieldError } from "react-hook-form";
import { TranslationScopes } from "@finbackoffice/enums";
import { ITranslateProps } from "@finbackoffice/site-core";
import Translate from "../translate/Translate";
import Tooltip from "../tooltip/Tooltip";
import styles from "./input-field.module.sass";

type EventHandler = (e: React.ChangeEvent<HTMLInputElement>) => void;

type ValueType<TValueAsNumber> = TValueAsNumber extends true ? number : string;

export type OnChangeHandler<TValueAsNumber> = (value: ValueType<TValueAsNumber>) => void;

interface Props<
    TType = HTMLInputTypeAttribute,
    TValueAsNumber = boolean,
    TIsEventHandler = boolean,
> {
    label?: string | ITranslateProps;
    iconClass?: string;
    innerIconClass?: string;
    wrapperClassname?: string;
    error?: Partial<FieldError> | { type: string; message: string };
    tooltip?: {
        render: string;
        variant?: string;
        condition?: string;
    };
    onInnerIconClick?: (e: SyntheticEvent) => void;
    onIconClick?: (e: SyntheticEvent) => void;
    required?: boolean;
    enableAutoComplete?: boolean;
    showFocus?: boolean;
    forwardRef?: (input: HTMLInputElement | null) => void; // remove
    onChange?: TIsEventHandler extends true ? EventHandler : OnChangeHandler<TValueAsNumber>;
    value?: TType extends "number"
        ? TValueAsNumber extends true | undefined
            ? number
            : string
        : string;
    valueAsNumber?: TValueAsNumber;
    name: string;
    type?: TType;
    isEventHandler?: TIsEventHandler;
}

const Input = forwardRef(
    <TType extends HTMLInputTypeAttribute, TValueAsNumber extends boolean>(
        {
            label,
            iconClass,
            innerIconClass,
            wrapperClassname,
            error,
            tooltip,
            onInnerIconClick,
            onIconClick,
            required,
            enableAutoComplete,
            showFocus,
            value,
            onChange,
            type,
            name,
            className,
            valueAsNumber,
            isEventHandler,
            ...rest
        }: Props<TType, TValueAsNumber> & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">,
        ref: Ref<HTMLInputElement>,
    ) => {
        const _valueAsNumber = valueAsNumber ?? (type === "number" ? true : false);
        const [autoComplete, setAutoComplete] = useState(enableAutoComplete ? "on" : "off");
        const inputRef = useRef<HTMLInputElement | null>();

        const handleRefSet = useCallback(
            (input: HTMLInputElement | null) => {
                inputRef.current = input;
                if (typeof ref === "function") {
                    ref(input);
                } else if (ref) {
                    (ref as React.MutableRefObject<HTMLInputElement | null>).current = input;
                }
            },
            [ref],
        );

        useEffect(() => {
            if (!enableAutoComplete && navigator?.userAgent.includes("Chrome")) {
                setAutoComplete("new-password");
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (showFocus) {
                inputRef.current?.focus();
            }
        }, [showFocus]);

        const handleInputChange = useCallback(
            (e: SyntheticEvent<HTMLInputElement>) => {
                if (isEventHandler) {
                    (onChange as EventHandler)(e as ChangeEvent<HTMLInputElement>);
                } else {
                    const val =
                        type === "number" && _valueAsNumber
                            ? e.currentTarget.valueAsNumber
                            : e.currentTarget.value;

                    if (typeof onChange === "function") {
                        (onChange as OnChangeHandler<TValueAsNumber>)(
                            val as ValueType<TValueAsNumber>,
                        );
                    }
                }
            },
            [_valueAsNumber, isEventHandler, onChange, type],
        );

        return (
            <div className={wrapperClassname}>
                {iconClass && <i className={iconClass} onClick={onIconClick} />}
                {label && (
                    <label htmlFor={name} className={styles.label}>
                        <Translate
                            tid={typeof label === "string" ? label : (label as ITranslateProps).tid}
                            namespace={
                                typeof label !== "string"
                                    ? (label as ITranslateProps).namespace
                                    : TranslationScopes.Common
                            }
                        />
                        :
                    </label>
                )}
                <div className={classNames(error && styles.fieldError, required && "required")}>
                    <input
                        ref={handleRefSet}
                        type={type}
                        id={name}
                        name={name}
                        onChange={handleInputChange}
                        value={_valueAsNumber ? value || "" : value}
                        className={className || styles.inputStyle}
                        autoComplete={autoComplete}
                        {...rest}
                    />
                    {innerIconClass && <i className={innerIconClass} onClick={onInnerIconClick} />}
                    {error && (
                        <span className={classNames(styles.error, "field-error")}>
                            {error.message}
                        </span>
                    )}
                    {tooltip && (
                        <Tooltip
                            targetRef={inputRef}
                            message={tooltip.render}
                            variant={tooltip.variant}
                        />
                    )}
                </div>
            </div>
        );
    },
);

/* const Input1 = <TType extends HTMLInputTypeAttribute, TValueAsNumber extends boolean>({
    label,
    iconClass,
    innerIconClass,
    wrapperClassname,
    error,
    tooltip,
    onInnerIconClick,
    onIconClick,
    required,
    enableAutoComplete,
    showFocus,
    forwardRef,
    value,
    onChange,
    type,
    name,
    className,
    valueAsNumber,
    ...rest
}: Props<TType, TValueAsNumber> & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
    const _valueAsNumber = valueAsNumber ?? (type === "number" ? true : false);
    const [autoComplete, setAutoComplete] = useState(enableAutoComplete ? "on" : "off");
    const inputRef = useRef<any>();

    const handleRefSet = useCallback(
        (input: HTMLInputElement | null) => {
            inputRef.current = input;
            forwardRef?.(input);
        },
        [inputRef, forwardRef],
    );

    useEffect(() => {
        if (!enableAutoComplete && navigator?.userAgent.includes("Chrome")) {
            setAutoComplete("new-password");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (showFocus) {
            inputRef.current.focus();
        }
    }, [showFocus]);

    const handleInputChange = useCallback(
        (e: SyntheticEvent<HTMLInputElement>) => {
            type ValueType = TType extends "number"
                ? TValueAsNumber extends true | undefined
                    ? number
                    : string
                : string;
            if (type === "number" && _valueAsNumber) {
                const val = e.currentTarget.valueAsNumber;
                onChange?.(val as ValueType);
            } else {
                const val = e.currentTarget.value;
                onChange?.(val as ValueType);
            }
        },
        [_valueAsNumber, onChange, type],
    );

    return (
        <div className={wrapperClassname}>
            {iconClass && <i className={iconClass} onClick={onIconClick} />}
            {label && (
                <label htmlFor={name} className={styles.label}>
                    <Translate
                        tid={typeof label === "string" ? label : (label as ITranslateProps).tid}
                        namespace={
                            typeof label !== "string"
                                ? (label as ITranslateProps).namespace
                                : TranslationScopes.Common
                        }
                    />
                    :
                </label>
            )}
            <div className={classNames(error && styles.fieldError, required && "required")}>
                <input
                    ref={handleRefSet}
                    type={type}
                    id={name}
                    name={name}
                    onChange={handleInputChange}
                    value={_valueAsNumber ? value || "" : value}
                    className={className || styles.inputStyle}
                    autoComplete={autoComplete}
                    {...rest}
                />
                {innerIconClass && <i className={innerIconClass} onClick={onInnerIconClick} />}
                {error && (
                    <span className={classNames(styles.error, "field-error")}>{error.message}</span>
                )}
                {tooltip && (
                    <Tooltip
                        targetRef={inputRef}
                        message={tooltip.render}
                        variant={tooltip.variant}
                    />
                )}
            </div>
        </div>
    );
}; */

export default Input;
