"use client";

import {
    FC,
    Fragment,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
} from "react";
import classNames from "classnames";
import { useParams, useRouter } from "next/navigation";
import dynamic from "next/dynamic";
import {
    ModalsContextV2,
    usePathnameWithLocal,
    useRuntimeConfig,
    useSearchParamsQuery,
    useSiteConfig,
} from "@finbackoffice/site-core";
import { formatAppNameText, ISiteConfigLayouts, MenuButtonsEnum } from "@finbackoffice/fe-core";
import Button from "components/base/button/Button";
import Translate from "components/base/translate/Translate";
import { MenuContext } from "components/menu/Menu";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import { Svg } from "components/base/svg/Svg";
import { ModalTypes, RouteNames } from "utils/constants";
import styles from "./compact.module.sass";

const CasinoTools = dynamic(() => import("components/menu/casino-tools/CasinoTools"), {
    ssr: false,
});

const Compact: FC = () => {
    const {
        betProcessing,
        betCountChange,
        onBetSlipButtonClickHandler,
        handleLiveButtonClick,
        handleSportButtonClick,
        hasBetSlipError,
        liveButtonForceActive,
        currentModal,
        betItems,
        menuScope,
    } = useContext(MenuContext);
    const router = useRouter();
    const params = useParams();
    const { path, pathnameValues, pathname } = usePathnameWithLocal();
    const { query } = useSearchParamsQuery();
    const { setCurrentModal } = useContext(ModalsContextV2); // refactor
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const casinoToolsModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const casinoScope = useMemo(() => pathnameValues[1], [pathnameValues]);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");

    const onCasinoToolsClick = useCallback(() => {
        if (currentModal === ModalTypes.CASINO_TOOLS) {
            setCurrentModal(null);
        } else {
            casinoToolsModalRef.current?.open();
        }
    }, [currentModal, setCurrentModal]);

    const onCasinoClick = useCallback(() => {
        const isCasinoLobbyView = path === `/${RouteNames.CASINO}`;
        if (!isCasinoLobbyView) {
            router.push(`/${RouteNames.CASINO}`);
        }
    }, [path, router]);

    useEffect(() => {
        if ([ModalTypes.CASINO_TOOLS].includes(currentModal || "")) {
            setCurrentModal(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoScope]);

    const renderMenu = useCallback(
        (name: string) => {
            const gameId = query?.gameId;
            switch (name) {
                case MenuButtonsEnum.Prematch:
                    const pathToPrematch = `/${RouteNames.SPORT}/${RouteNames.PREMATCH}`;
                    const isPrematchView = pathname?.includes(pathToPrematch);
                    return (
                        <Button
                            key={name}
                            type="button"
                            variant="custom"
                            onClick={handleSportButtonClick}
                            className={classNames(
                                styles.sport,
                                !liveButtonForceActive && isPrematchView && styles.active,
                            )}>
                            <>
                                {isPrematchView && (!!params?.gameId || !!params?.tournamentId) && (
                                    <i className={styles.navigateTo} />
                                )}
                                <p>
                                    <Translate tid="pageName_sport" />
                                </p>
                                <Svg
                                    src={`/${formatAppNameText(
                                        COMMON_SITE_CONFIGS.appName,
                                    )}/mobile/menu-sport.svg`}
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </>
                        </Button>
                    );
                case MenuButtonsEnum.LiveCasino:
                    const liveCasinoViewHref = `/${RouteNames.CASINO}/${RouteNames.LIVE_CASINO}`;
                    const isLiveCasinoView = pathname?.includes(liveCasinoViewHref);
                    const handleLiveCasinoClick = () => {
                        if (!isLiveCasinoView || (isLiveCasinoView && gameId)) {
                            router.push(liveCasinoViewHref);
                        }
                    };
                    return (
                        <Button
                            type="button"
                            variant="custom"
                            onClick={handleLiveCasinoClick}
                            key={name}
                            href={liveCasinoViewHref}
                            className={classNames(
                                styles.liveCasino,
                                isLiveCasinoView && styles.active,
                            )}>
                            <>
                                {isLiveCasinoView && gameId && <i className={styles.navigateTo} />}
                                <p>
                                    <Translate tid="pageName_livecasino" />
                                </p>
                                <Svg
                                    src={`/${formatAppNameText(
                                        COMMON_SITE_CONFIGS.appName,
                                    )}/common/base-icons/live-casino-icon.svg`}
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </>
                        </Button>
                    );
                case MenuButtonsEnum.BetSlip:
                    return (
                        <Button
                            key={name}
                            type="button"
                            variant="custom"
                            onClick={onBetSlipButtonClickHandler}
                            className={classNames(
                                styles.betslip,
                                currentModal === ModalTypes.BET_SLIP && styles.betSlipActive,
                            )}
                            data-testid="open-betslip-button">
                            <div
                                className={classNames(
                                    hasBetSlipError && styles.betslipHasErrorBtn,
                                )}>
                                <Svg src="/common/mobile/base-icons/menu-betslip.svg" />
                                <span className={classNames(betCountChange && styles.animate)}>
                                    {betItems.length}
                                    {betProcessing && (
                                        <span className={styles.spinner}>
                                            <Svg src="/common/desktop/base-icons/spinner.svg" />
                                        </span>
                                    )}
                                </span>
                            </div>
                            <p>
                                <Translate tid="menu_betSlip" />
                            </p>
                        </Button>
                    );
                case MenuButtonsEnum.Slots:
                    const slotsViewHref = `/${RouteNames.CASINO}/${RouteNames.SLOTS}`;
                    const isSlotsView = pathname?.includes(slotsViewHref);

                    const handleSlotsClick = () => {
                        if (!isSlotsView || (isSlotsView && gameId)) {
                            router.push(slotsViewHref);
                        }
                    };
                    return (
                        <Button
                            type="button"
                            variant="custom"
                            onClick={handleSlotsClick}
                            key={name}
                            className={classNames(styles.slots, isSlotsView && styles.active)}>
                            <>
                                {isSlotsView && gameId && <i className={styles.navigateTo} />}
                                <p>
                                    <Translate tid="pageName_slots" />
                                </p>
                                <Svg
                                    src={`/${formatAppNameText(
                                        COMMON_SITE_CONFIGS.appName,
                                    )}/common/base-icons/slots-icon.svg`}
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </>
                        </Button>
                    );
                case MenuButtonsEnum.Live:
                    return (
                        <Button
                            key={name}
                            type="button"
                            variant="custom"
                            onClick={handleLiveButtonClick}
                            className={classNames(
                                styles.live,
                                (liveButtonForceActive ||
                                    pathname?.includes(RouteNames.CALENDAR) ||
                                    pathname?.includes(
                                        `/${RouteNames.SPORT}/${RouteNames.LIVE}`,
                                    )) &&
                                    styles.active,
                            )}>
                            <>
                                {(params?.liveGameRoutes as string[])?.length > 0 && (
                                    <i className={styles.navigateTo} />
                                )}
                                <p>
                                    <Translate tid="pageName_live" />
                                </p>
                                <Svg
                                    src="/common/mobile/base-icons/menu-live.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </>
                        </Button>
                    );
                case MenuButtonsEnum.Casino:
                    return (
                        <Button
                            key={name}
                            type="button"
                            variant="custom"
                            onClick={onCasinoClick}
                            className={classNames(
                                styles.casino,
                                pathname?.includes(`/${RouteNames.CASINO}`) &&
                                    !pathname?.includes(RouteNames.POLICIES) &&
                                    styles.active,
                            )}>
                            {pathname?.includes(`/${RouteNames.CASINO}/`) && gameId && (
                                <i className={styles.navigateTo} />
                            )}
                            <p>
                                <Translate tid="menu_casino" />
                            </p>
                            <Svg
                                src={`/${formatAppNameText(
                                    COMMON_SITE_CONFIGS.appName,
                                )}/mobile/menu-casino.svg`}
                                wrapper="span"
                                className="svg-icon"
                            />
                        </Button>
                    );
                case MenuButtonsEnum.CasinoTools:
                    return (
                        <Fragment key={name}>
                            <Button
                                type="button"
                                variant="custom"
                                onClick={onCasinoToolsClick}
                                className={classNames(
                                    styles.casinoButton,
                                    currentModal === ModalTypes.CASINO_TOOLS &&
                                        styles.casinoButtonActive,
                                )}>
                                <div>
                                    <Svg
                                        src="/common/mobile/base-icons/providers.svg"
                                        className={styles.providers}
                                        wrapper="span"
                                    />
                                    <Svg src="/common/mobile/base-icons/menu-betslip.svg" />
                                    <span className={styles.arrow} />
                                    <Svg
                                        src="/common/mobile/base-icons/search.svg"
                                        className={styles.search}
                                        wrapper="span"
                                    />
                                </div>
                                <p>
                                    <Translate tid="menu_casino" />
                                </p>
                            </Button>
                        </Fragment>
                    );
                default:
                    return null;
            }
        },
        [
            query?.gameId,
            pathname,
            handleSportButtonClick,
            liveButtonForceActive,
            params?.gameId,
            params?.tournamentId,
            params?.liveGameRoutes,
            COMMON_SITE_CONFIGS.appName,
            onBetSlipButtonClickHandler,
            currentModal,
            hasBetSlipError,
            betCountChange,
            betItems.length,
            betProcessing,
            handleLiveButtonClick,
            onCasinoClick,
            onCasinoToolsClick,
            router,
        ],
    );

    return (
        <>
            {siteLayoutsConfig?.menu?.[menuScope]?.map(renderMenu)}
            <Modal
                ref={casinoToolsModalRef}
                type={ModalTypes.CASINO_TOOLS}
                animateVariant="bottom"
                styleClass={styles.menuCasinoModal}>
                <CasinoTools />
            </Modal>
        </>
    );
};

export default Compact;
