"use client";

import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
} from "react";
import { convertGamesToTournaments, FeedContext } from "@finbackoffice/site-core";
import { useParams } from "next/navigation";
import {
    IEventFeedResponse,
    ISportFeedResponse,
    ITopLeagueFeedResponse,
} from "@finbackoffice/websocket-client";
import { FeedTypeEnum, useNotification, usePrematchSports, useSocketStorage } from "hooks";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Footer from "components/footer/Footer";
import MainContent from "components/MainContent";
import PrematchFaq from "components/pages/sport/prematch/faq/PrematchFaq";
import PrematchHeader from "components/pages/sport/prematch/header/PrematchHeader";
import SiteNotification from "components/base/notification/SiteNotification";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import SportsLayout from "../SportsLayout";

export const PrematchFeedSubscription: FC<PropsWithChildren> = ({ children }) => {
    usePrematchSports();
    return children;
};

type IPrematchLayoutContext = {
    prematchFeed: ISportFeedResponse[];
    activeSportId?: number;
    activeSportTournaments: ITopLeagueFeedResponse[];
    getGamesByTournamentId: (id: number) => IEventFeedResponse[];
};

export const PrematchLayoutContext = createContext<IPrematchLayoutContext>(null as any);

const PrematchLayout: FC<PropsWithChildren> = ({ children }) => {
    const { prematchFeedError, prematchFeed } = useContext(FeedContext);
    const params = useParams();
    const { getEventsBySportId } = useSocketStorage(FeedTypeEnum.prematch);
    const { setNotification, notification } = useNotification();

    useEffect(() => {
        if (prematchFeedError) {
            setNotification({
                type: "error",
                error: "Error",
                message: prematchFeedError,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prematchFeedError]);

    const activeSportId = useMemo(
        () => prematchFeed.find((sport) => sport.slug === params?.sport)?.id ?? prematchFeed[0]?.id,
        [prematchFeed, params?.sport],
    );

    const activeSportEvents = useMemo(
        () => (activeSportId ? getEventsBySportId(activeSportId) : []),
        [activeSportId, getEventsBySportId],
    );

    const activeSportTournaments = useMemo(
        () => convertGamesToTournaments(activeSportEvents),
        [activeSportEvents],
    );

    const getGamesByTournamentId = useCallback(
        (id: number) => activeSportEvents.filter((event) => event.tournament_id === id),
        [activeSportEvents],
    );

    const renderContent = useMemo(() => {
        if (notification.type) {
            return (
                <SiteNotification
                    type={notification.type}
                    title={notification.error}
                    message={notification.message}
                />
            );
        } else {
            return children;
        }
    }, [children, notification.error, notification.message, notification.type]);

    const value = useMemo(
        () => ({
            prematchFeed,
            activeSportId,
            activeSportTournaments,
            getGamesByTournamentId,
        }),
        [activeSportId, activeSportTournaments, getGamesByTournamentId, prematchFeed],
    );

    return (
        <PrematchFeedSubscription>
            <PrematchLayoutContext.Provider value={value}>
                <MainContent>
                    <PrematchHeader />
                    <FadeInAnimation>
                        <div>
                            <ErrorBoundary name={SportsLayout.name}>
                                <SportsLayout
                                    sports={prematchFeed}
                                    activeSportId={activeSportId || undefined}>
                                    {renderContent}
                                </SportsLayout>
                            </ErrorBoundary>
                        </div>
                    </FadeInAnimation>
                    <PrematchFaq />
                    <Footer />
                </MainContent>
            </PrematchLayoutContext.Provider>
        </PrematchFeedSubscription>
    );
};

export default PrematchLayout;
