"use client";

import { FC } from "react";
import styles from "./preload-component.module.sass";

type IProps = {
    style?: React.CSSProperties;
};

const PreloadComponent: FC<IProps> = ({ style }) => {
    return (
        <div className={styles.container} style={style}>
            <div className={styles.loader}>
                <span className={styles.one} />
                <span className={styles.two} />
                <span className={styles.tree} />
            </div>
        </div>
    );
};

export default PreloadComponent;
