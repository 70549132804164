"use client";

import { createContext, FC, useCallback, useContext, useMemo, useState } from "react";
import {
    calculateRestOfDayInHoursRange,
    convertGamesToTournaments,
    I18nextProvider,
    useWebsocketUpdates,
} from "@finbackoffice/site-core";
import { useParams } from "next/navigation";
import {
    IClientError,
    IEventFeedResponse,
    ITopLeagueFeedResponse,
} from "@finbackoffice/websocket-client";
import { convertGamesToSports } from "utils/helpers";
import LiveLayout from "components/layout/sportsbook/live/LiveLayout";
import { CommonTranslationsContext } from "../../contexts";

interface IEventsState {
    data: IEventFeedResponse[];
    loading: boolean;
}

type IUpcomingLayoutContext = {
    activeSportTournaments: ITopLeagueFeedResponse[];
    getGamesByTournamentId: (id: number) => IEventFeedResponse[];
    loading: boolean;
};

export const UpcomingLayoutContext = createContext<IUpcomingLayoutContext>(null as any);

export const SportUpcomingLayout: FC<{
    children: React.ReactNode;
    translations: Record<string, any>;
}> = ({ children, translations }) => {
    const translationsContext = useContext(CommonTranslationsContext);
    const params = useParams();
    const [events, setEvents] = useState<IEventsState>({
        data: [],
        loading: true,
    });

    const hoursRange = useMemo(() => calculateRestOfDayInHoursRange(), []);

    const onSocketUpdate = useCallback((events: IEventFeedResponse[], error: IClientError) => {
        if (!error) {
            setEvents({
                data: events,
                loading: false,
            });
        } else {
            setEvents((state) => ({
                ...state,
                loading: false,
            }));
        }
    }, []);

    const payload = useMemo(() => ({ hours: hoursRange }), [hoursRange]);

    useWebsocketUpdates("upcoming", onSocketUpdate, true, payload);

    const sports = useMemo(() => convertGamesToSports(events.data), [events]);

    const activeSportId = useMemo(
        () => sports.find((sport) => sport.slug === params?.sport)?.id ?? sports[0]?.id,
        [sports, params?.sport],
    );

    const activeSportEvents = useMemo(
        () => events.data.filter((event) => event.sport_id === activeSportId),
        [activeSportId, events],
    );

    const activeSportTournaments = useMemo(
        () => convertGamesToTournaments(activeSportEvents),
        [activeSportEvents],
    );

    const getGamesByTournamentId = useCallback(
        (id: number) => activeSportEvents.filter((event) => event.tournament_id === id),
        [activeSportEvents],
    );

    const value = useMemo(
        () => ({
            activeSportTournaments,
            getGamesByTournamentId,
            loading: events.loading,
        }),
        [activeSportTournaments, getGamesByTournamentId, events.loading],
    );

    const translationsValue = useMemo(
        () => ({
            ...translationsContext.translations,
            ...translations,
        }),
        [translations, translationsContext.translations],
    );

    return (
        <I18nextProvider translations={translationsValue}>
            <UpcomingLayoutContext.Provider value={value}>
                <LiveLayout sport={sports} activeSportId={activeSportId}>
                    {children}
                </LiveLayout>
            </UpcomingLayoutContext.Provider>
        </I18nextProvider>
    );
};
