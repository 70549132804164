"use client";

import { FC, useCallback, useContext, useMemo } from "react";
import { usePathname, useRouter } from "next/navigation";
import { FavoriteEventsContext, FeedContext } from "@finbackoffice/site-core";
import classNames from "classnames";
import ActiveLink from "components/base/active-link/ActiveLink";
import Translate from "components/base/translate/Translate";
import Button from "components/base/button/Button";
import { useDate } from "hooks";
import { Svg } from "components/base/svg/Svg";
import { PrematchContext } from "contexts";
import { RouteNames } from "utils/constants";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import CalendarDateSelector from "./CalendarDateSelector";
import styles from "./live-header.module.sass";

const LiveHeader: FC = () => {
    const router = useRouter();
    const pathname = usePathname();
    const { getTime, endOfDay, addDays, startOfDay } = useDate();
    const { favLiveLeaguesGameCount } = useContext(FavoriteEventsContext);
    const { calendarDate, setCalendarDate } = useContext(PrematchContext);
    const { liveTopLeaguesGamesCount } = useContext(FeedContext);
    const isCalendar = useMemo(() => pathname?.includes(RouteNames.CALENDAR), [pathname]);

    const calendarSelectorSettings = useMemo(
        () => ({
            from: getTime(addDays(endOfDay(new Date()), 1)),
            // from: getTime(Date.now()),
            to: getTime(addDays(endOfDay(new Date()), 3)),
        }),
        [addDays, endOfDay, getTime],
    );

    const handleSelectorChange = useCallback(
        (val: number) => {
            if (!isCalendar) {
                router.push(`/${RouteNames.SPORT}/${RouteNames.CALENDAR}`);
            }

            if (
                !calendarDate ||
                (calendarDate && !(val >= calendarDate.from && val <= calendarDate.to))
            ) {
                setCalendarDate({
                    from: getTime(startOfDay(val)),
                    to: getTime(endOfDay(val)),
                });
            }
        },
        [calendarDate, endOfDay, getTime, isCalendar, router, setCalendarDate, startOfDay],
    );

    return (
        <FadeInAnimation>
            <div className={styles.liveHeader}>
                <div className={styles.liveTabs}>
                    <ActiveLink
                        href={`/${RouteNames.SPORT}/${RouteNames.LIVE}`}
                        includes={`/${RouteNames.SPORT}/${RouteNames.LIVE}`}
                        activeClassName={styles.active}
                        className={styles.liveTab}>
                        <Translate tid="header_dateSelectorLive" />
                    </ActiveLink>
                    <ActiveLink
                        href={`/${RouteNames.SPORT}/${RouteNames.UPCOMING}`}
                        includes={`/${RouteNames.SPORT}/${RouteNames.UPCOMING}`}
                        activeClassName={styles.active}
                        className={styles.liveTab}>
                        <Translate tid="header_dateSelectorLiveToday" />
                    </ActiveLink>
                    <CalendarDateSelector
                        from={calendarSelectorSettings.from}
                        to={calendarSelectorSettings.to}
                        currentDate={calendarDate}
                        onChange={handleSelectorChange}
                    />

                    <Button
                        type="button"
                        disabled={liveTopLeaguesGamesCount + favLiveLeaguesGameCount === 0}
                        className={classNames(styles.favoritesTab, {
                            [styles.active]: pathname?.includes(RouteNames.TOP),
                        })}
                        onClick={() =>
                            router.push(`/${RouteNames.SPORT}/${RouteNames.LIVE}/${RouteNames.TOP}`)
                        }>
                        <span className={styles.favoritesCount}>
                            {liveTopLeaguesGamesCount + favLiveLeaguesGameCount}
                        </span>
                        <Svg
                            src="/common/mobile/base-icons/favourites.svg"
                            wrapper="span"
                            className={styles.favoritesStar}
                        />
                    </Button>
                </div>
            </div>
        </FadeInAnimation>
    );
};

export default LiveHeader;
