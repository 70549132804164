"use client";

import { NextLayoutComponentType } from "next";
import { useParams } from "next/navigation";
import { createContext, useContext, useMemo } from "react";
import { FeedContext } from "@finbackoffice/site-core";
import { ICategoryFeedResponse, ISearchOptions } from "@finbackoffice/websocket-client";
import { FeedTypeEnum, useSocketStorage } from "hooks";
import MainContent from "components/MainContent";
import Footer from "components/footer/Footer";
import ResultsHeader from "components/pages/sport/results/header/ResultsHeader";
import ErrorBoundary from "../../../base/error-boundary/ErrorBoundary";
import SportsLayout from "../SportsLayout";

type IResultsLayoutContext = {
    activeSportId?: number;
    getCategories: (sportId: number, options?: ISearchOptions) => ICategoryFeedResponse[];
    ready: boolean;
};

export const ResultsLayoutContext = createContext<IResultsLayoutContext>(null as any);

const ResultsLayout: NextLayoutComponentType = ({ children }) => {
    const params = useParams();
    const { getCategories, getSportBySlug, connected } = useSocketStorage(FeedTypeEnum.results);
    const { resultsFeed, resultsIsLoading, hasResultsSports } = useContext(FeedContext);
    const ready = connected && !resultsIsLoading && hasResultsSports;

    const activeSportId = useMemo(
        () =>
            ready ? (getSportBySlug(params?.sport as string)?.id ?? resultsFeed[0].id) : undefined,
        [getSportBySlug, ready, params?.sport, resultsFeed],
    );

    const value = useMemo(
        () => ({
            activeSportId,
            getCategories,
            ready,
        }),
        [activeSportId, getCategories, ready],
    );

    return (
        <ResultsLayoutContext.Provider value={value}>
            <MainContent>
                <ResultsHeader />
                <div>
                    <ErrorBoundary name={SportsLayout.name}>
                        <SportsLayout
                            sports={resultsFeed}
                            activeSportId={activeSportId}
                            showGamesCount={false}>
                            {children}
                        </SportsLayout>
                    </ErrorBoundary>
                </div>
                <Footer />
            </MainContent>
        </ResultsLayoutContext.Provider>
    );
};

export default ResultsLayout;
