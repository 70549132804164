"use client";

import { ICasinoFavoriteResponse } from "@finbackoffice/clientbff-client";
import React, {
    FC,
    RefObject,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { ModalsContextV2, useRuntimeConfig } from "@finbackoffice/site-core"; // refactor
import { IModalForwardRefProps } from "components/base/modal/Modal";
import { useClearModalOnUnmount } from "hooks";
import Img from "components/base/img/Img";
import { ModalTypes } from "utils/constants";
import Compact from "./layouts/compact/Compact";
import Circle from "./circle/Circle";
import styles from "./casino-favorites-panel.module.sass";

type Props = {
    games: ICasinoFavoriteResponse[];
    openFavoritesModal?: () => void;
    createCasinoFavorite: (gameId: string) => Promise<void>;
    deleteCasinoFavorite: (gameId: string) => Promise<void>;
};

type ICasinoFavoritesPanelContext = Omit<Props, "games"> & {
    selectedGame: ICasinoFavoriteResponse | null;
    setSelectedGame: (val: ICasinoFavoriteResponse | null) => void;
    modalRef: RefObject<IModalForwardRefProps>;
    modalType: string;
};

export const CasinoFavoritesPanelContext = createContext<ICasinoFavoritesPanelContext>(null as any);

const CasinoFavoritesPanel: FC<Props> = ({
    games,
    openFavoritesModal,
    createCasinoFavorite,
    deleteCasinoFavorite,
}) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { currentModal, setCurrentModal } = useContext(ModalsContextV2); // refactor
    useClearModalOnUnmount(ModalTypes.CASINO_FAVORITE_THUMBNAIL);
    const modalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const [modalType, setModalType] = useState<"left" | "right" | "">("");

    const [selectedGame, setSelectedGame] = useState<ICasinoFavoriteResponse | null>(null);
    const MIN_VISIBLE_CIRCLES = 7;

    useEffect(() => {
        if (selectedGame) {
            if (currentModal !== ModalTypes.CASINO_FAVORITE_THUMBNAIL) {
                modalRef.current?.open();
            }
        } else if (currentModal === ModalTypes.CASINO_FAVORITE_THUMBNAIL) {
            setCurrentModal(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGame]);

    useEffect(() => {
        if (selectedGame && !games.find((game) => game.game_id === selectedGame?.game_id)) {
            setSelectedGame(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [games]);

    const handleSetSelectedGame = useCallback(
        (
            val: ICasinoFavoriteResponse | null,
            modalPosition?: number,
            modalType?: "left" | "right",
        ) => {
            if (modalType) {
                setModalType(modalType);
            }

            if (modalPosition) {
                modalRef.current?.setStyle?.({ left: `${modalPosition}px` });
            }

            setSelectedGame(val);
        },
        [],
    );

    const value = useMemo(
        () => ({
            modalType,
            createCasinoFavorite,
            deleteCasinoFavorite,
            selectedGame,
            modalRef,
            setSelectedGame,
        }),
        [
            modalType,
            createCasinoFavorite,
            deleteCasinoFavorite,
            selectedGame,
            modalRef,
            setSelectedGame,
        ],
    );

    return (
        <CasinoFavoritesPanelContext.Provider value={value}>
            <div className={styles.favoritesBar}>
                <Img
                    source={`${ASSETS_URL}/common/mobile/base-icons/casino-favorite-star.svg`}
                    alt="Favorites"
                    width={21}
                    height={22}
                    onClick={openFavoritesModal}
                    wrapperClassName={styles.favoritesBarIcon}
                />
                {games.map(
                    (favoriteGame, index) =>
                        index + 1 <= MIN_VISIBLE_CIRCLES && (
                            <Circle
                                key={favoriteGame.game_id}
                                favoriteGame={favoriteGame}
                                setSelectedGame={handleSetSelectedGame}
                                isActive={selectedGame?.game_id === favoriteGame.game_id}
                            />
                        ),
                )}
                <span className={styles.openFavoritesBtn} onClick={openFavoritesModal}>
                    {games.length > MIN_VISIBLE_CIRCLES && `+${games.length - MIN_VISIBLE_CIRCLES}`}
                </span>
            </div>
            <Compact />
        </CasinoFavoritesPanelContext.Provider>
    );
};

export default CasinoFavoritesPanel;
