"use client";

import { ISiteConfigLayouts, SignupLayoutEnum } from "@finbackoffice/fe-core";
import classNames from "classnames";
import {
    useSiteConfig,
    CpfSignupContext,
    ConfigContextV2,
    ModalsContextV2,
} from "@finbackoffice/site-core";
import { FC, useContext } from "react";
import Signup from "components/account/signup/Signup";
import Modal from "components/base/modal/Modal";
import { ModalTypes } from "utils/constants";
import styles from "./header.module.sass";

const SignupModal: FC = () => {
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const { step } = useContext(CpfSignupContext);
    const { siteRegistrationConfig } = useContext(ConfigContextV2);
    const { signupModalRef } = useContext(ModalsContextV2);
    const isCpfSignupLayout = [
        SignupLayoutEnum.CPFSignup,
        SignupLayoutEnum.CPFSignup1Step,
        SignupLayoutEnum.CPFSignupKyc,
    ].includes(siteLayoutsConfig._signup.type);
    const isAfroSignupLayout = siteLayoutsConfig._signup.type === SignupLayoutEnum.Afro;
    const isDefaultSignUpLayout = siteLayoutsConfig._signup.type === SignupLayoutEnum.Default;

    return (
        <Modal
            ref={signupModalRef}
            type={ModalTypes.SIGNUP}
            styleClass={classNames(
                styles.signupCompact,
                isDefaultSignUpLayout && styles.signupDefault,
                isCpfSignupLayout && styles.signupCpf,
                isAfroSignupLayout && styles.signupAfro,
            )}
            closeButton={step !== "second" || !siteRegistrationConfig?.auto_id_verification}
            animateVariant="bottom">
            <Signup />
        </Modal>
    );
};

export default SignupModal;
