"use client";

import { Feed } from "@finbackoffice/site-core";
import { FC, PropsWithChildren } from "react";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import LiveHeader from "components/pages/sport/live/header/LiveHeader";
import Footer from "components/footer/Footer";
import MainContent from "components/MainContent";
import SportsLayout from "../SportsLayout";

type ILiveLayoutProps = {
    sport: Feed;
    activeSportId?: number;
    onSportClick?: (sportId: number) => void;
};

const LiveLayout: FC<PropsWithChildren & ILiveLayoutProps> = ({
    children,
    sport,
    activeSportId,
    onSportClick,
}) => {
    return (
        <MainContent>
            <LiveHeader />
            <div>
                <ErrorBoundary name={SportsLayout.name}>
                    <SportsLayout
                        sports={sport}
                        activeSportId={activeSportId}
                        onSportClick={onSportClick}>
                        {children}
                    </SportsLayout>
                </ErrorBoundary>
            </div>
            <Footer />
        </MainContent>
    );
};

export default LiveLayout;
