"use client";

import { FC, PropsWithChildren, useContext } from "react";
import { AuthContext, useRuntimeConfig } from "@finbackoffice/site-core";
import { formatAppNameText } from "@finbackoffice/fe-core";
import ActiveLink from "components/base/active-link/ActiveLink";
import MainContent from "components/MainContent";
import { Svg } from "components/base/svg/Svg";
import { RouteNames } from "utils/constants";
import styles from "./account-bets-layout.module.sass";
import AccountBetsHeader from "./AccountBetsHeader";

const AccountBetsLayout: FC<PropsWithChildren> = ({ children }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { isUserLoggedIn } = useContext(AuthContext);

    if (!isUserLoggedIn) {
        return children;
    }

    return (
        <MainContent variant="accountView">
            <div className={styles.myBets}>
                <AccountBetsHeader>
                    <ActiveLink
                        href={`/${RouteNames.ACCOUNT}/${RouteNames.BETS}/${RouteNames.SPORT}`}
                        activeClassName="myBetsTabActive"
                        shallow>
                        <Svg
                            src={`/${formatAppNameText(
                                COMMON_SITE_CONFIGS.appName,
                            )}/mobile/menu-sport.svg`}
                            wrapper="span"
                            className="svg-icon"
                        />
                    </ActiveLink>
                    <ActiveLink
                        data-testid="casino-bets-tab"
                        href={`/${RouteNames.ACCOUNT}/${RouteNames.BETS}/${RouteNames.CASINO}`}
                        activeClassName="myBetsTabActive"
                        shallow>
                        <Svg
                            src={`/${formatAppNameText(
                                COMMON_SITE_CONFIGS.appName,
                            )}/common/base-icons/live-casino-icon.svg`}
                            wrapper="span"
                            className="svg-icon"
                        />
                    </ActiveLink>
                </AccountBetsHeader>
                {children}
            </div>
        </MainContent>
    );
};

export default AccountBetsLayout;
