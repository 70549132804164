"use client";

import {
    FC,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { IPopupResponse } from "@finbackoffice/clientbff-client";
import { HOUR_IN_MS } from "@finbackoffice/fe-core";
import {
    AuthContext,
    BrowserStorageContext,
    BrowserStorageIdsEnum,
    ModalsContextV2, // refactor
    PopupBannersContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import { useRouter } from "next/navigation";
import Img from "components/base/img/Img";
import { Svg } from "components/base/svg/Svg";
import Button from "components/base/button/Button";
import Video from "components/base/video/Video";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import { RouteNames, ModalTypes } from "utils/constants";
import styles from "./popup-banners.module.sass";

type IProps = { banners: IPopupResponse[] };

export const PopupBanners: FC<IProps> = ({ banners }) => {
    const router = useRouter();
    const { loginModalRef, currentModal, setCurrentModal } = useContext(ModalsContextV2);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { isUserLoggedIn } = useContext(AuthContext);
    const { get } = useContext(BrowserStorageContext);
    const { updateStoragePopupBanners } = useContext(PopupBannersContext);

    const bannersMemo = useMemo(() => {
        const storageBanners = get(BrowserStorageIdsEnum.POPUP_BANNERS) ?? {};

        return banners.filter(
            (item) =>
                !storageBanners[item.id] ||
                Date.now() - storageBanners[item.id]?.last_seen_timestamp >
                    COMMON_SITE_CONFIGS.popupBannersShowInterval * HOUR_IN_MS,
        );
    }, [get, banners, COMMON_SITE_CONFIGS.popupBannersShowInterval]);
    const ref: RefObject<IModalForwardRefProps> = useRef(null);
    const [closedBanners, setClosedBanners] = useState<number[]>([]);

    useEffect(() => {
        if (bannersMemo.length > 0 && !currentModal) {
            ref.current?.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersMemo]);

    useEffect(() => {
        if (bannersMemo.length === closedBanners.length) {
            setCurrentModal(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closedBanners.length]);

    const renderMedia = useCallback(
        (src: string, alt: string) => {
            if (!src) return null;

            const isVideo = src.includes("mp4");
            if (isVideo) {
                return (
                    <Video
                        src={src}
                        poster={`${ASSETS_URL}/common/desktop/base-icons/notification-banner-preload.webp`}
                    />
                );
            } else {
                return (
                    <Img
                        width={0}
                        height={0}
                        style={{ width: "100%", height: "auto" }}
                        source={src}
                        alt={alt}
                        // placeholder="blur"
                        // blurDataURL={`${ASSETS_URL}/common/desktop/base-icons/notification-banner-preload.webp`}
                    />
                );
            }
        },
        [ASSETS_URL],
    );

    const onBannerClick = useCallback(
        (banner: IPopupResponse) => {
            const href = banner.href;
            const isCasinoGameHref = href?.includes(RouteNames.CASINO) && href?.includes("forFun");
            if (isCasinoGameHref && !isUserLoggedIn) {
                loginModalRef.current?.open();
            } else {
                setCurrentModal(null);
                router.push(`/${href}`);
            }
        },
        [isUserLoggedIn, loginModalRef, setCurrentModal, router],
    );

    const renderLink = useCallback(
        (banner: IPopupResponse) =>
            !banner.href?.includes("://") ? (
                <Button type="button" onClick={() => onBannerClick(banner)}>
                    {renderMedia(banner.bg_media?.url ?? "", banner.name)}
                </Button>
            ) : (
                <a title={banner.name} target="_blank" href={`${banner.href}`} rel="noreferrer">
                    {renderMedia(banner.bg_media?.url ?? "", banner.name)}
                </a>
            ),
        [onBannerClick, renderMedia],
    );

    const handleOnClose = useCallback(() => {
        updateStoragePopupBanners(closedBanners);
    }, [updateStoragePopupBanners, closedBanners]);

    return (
        <Modal
            ref={ref}
            type={ModalTypes.BANNERS}
            styleClass={styles.bannerModal}
            animateVariant="opacity"
            onClose={handleOnClose}>
            <div className={styles.banners}>
                {bannersMemo
                    .filter((item) => !closedBanners.includes(item.id))
                    .map((banner) => {
                        return (
                            <div key={banner.id} className={styles.bannerItem}>
                                <Svg
                                    src="/common/desktop/base-icons/account-cancel.svg"
                                    wrapper="span"
                                    className={styles.close}
                                    onClick={() =>
                                        setClosedBanners((state) => [...state, banner.id])
                                    }
                                />
                                {banner.href ? (
                                    renderLink(banner)
                                ) : (
                                    <div>
                                        {renderMedia(banner.bg_media?.url ?? "", banner.name)}
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
        </Modal>
    );
};
