"use client";

import { FC, useContext, useMemo } from "react";
import { I18nextProvider } from "@finbackoffice/site-core";
import { CommonTranslationsContext } from "contexts";
import BonusesLayout from "components/layout/bonuses/BonusesLayout";

export const BonusesLayoutContainer: FC<{
    children: React.ReactNode;
    translations: Record<string, any>;
}> = ({ children, translations }) => {
    const translationsContext = useContext(CommonTranslationsContext);

    const value = useMemo(
        () => ({
            ...translationsContext.translations,
            ...translations,
        }),
        [translations, translationsContext.translations],
    );

    return (
        <I18nextProvider translations={value}>
            <BonusesLayout>{children}</BonusesLayout>
        </I18nextProvider>
    );
};
