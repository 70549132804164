"use client";

import { FC, useContext, useMemo } from "react";
import { I18nextProvider } from "@finbackoffice/site-core";
import { usePathname } from "next/navigation";
import { CommonTranslationsContext } from "contexts";
import { RouteNames } from "utils/constants";
import { AccountCommonLayout } from "components/layout/common/AccountCommonLayout";

export const AccountLayoutContainer: FC<{
    children: React.ReactNode;
    translations: Record<string, any>;
}> = ({ children, translations }) => {
    const translationsContext = useContext(CommonTranslationsContext);
    const pathname = usePathname();

    const value = useMemo(
        () => ({
            ...translationsContext.translations,
            ...translations,
        }),
        [translations, translationsContext.translations],
    );

    return (
        <I18nextProvider translations={value}>
            {!pathname?.includes(RouteNames.BETS) && (
                <AccountCommonLayout>{children}</AccountCommonLayout>
            )}
            {pathname?.includes(RouteNames.BETS) && children}
        </I18nextProvider>
    );
};
