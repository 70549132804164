"use client";

import { FC, useContext, useEffect } from "react";
import { CasinoFavoritesContext } from "@finbackoffice/site-core";
import { ICasinoFavoriteResponse } from "@finbackoffice/clientbff-client";
import CasinoLayout from "components/layout/casino/CasinoLayout";

export const CasinoLayoutContainer: FC<{
    children: React.ReactNode;
    favoriteGames: ICasinoFavoriteResponse[] | null;
}> = ({ children, favoriteGames }) => {
    const { updateFavoriteGames } = useContext(CasinoFavoritesContext);

    useEffect(() => {
        if (!favoriteGames) {
            return;
        }

        updateFavoriteGames(favoriteGames);
    }, [favoriteGames, updateFavoriteGames]);

    return <CasinoLayout>{children}</CasinoLayout>;
};
