"use client";

import { FC, useContext, useMemo } from "react";
import { AuthContext, I18nextProvider, RuntimeConfigContext } from "@finbackoffice/site-core";
import { CommonTranslationsContext } from "../../contexts";
import CookiesConsent from "components/cookie-consent/CookiesConsent";
import FloatingLogin from "components/floating-login/FloatingLogin";

export const MainLayoutComponents: FC<{
    translations: Record<string, any>;
}> = ({ translations }) => {
    const { isUserLoggedIn } = useContext(AuthContext);
    const runtimeConfig = useContext(RuntimeConfigContext);

    const translationsContext = useContext(CommonTranslationsContext);

    const value = useMemo(
        () => ({
            ...translationsContext.translations,
            ...translations,
        }),
        [translations, translationsContext.translations],
    );

    return (
        <I18nextProvider translations={value}>
            {runtimeConfig.COMMON_SITE_CONFIGS.cookiesConsentPopup && <CookiesConsent />}
            {runtimeConfig.COMMON_SITE_CONFIGS.login.floatingLogin && !isUserLoggedIn && (
                <FloatingLogin />
            )}
        </I18nextProvider>
    );
};
