"use client";

import classNames from "classnames";
import { usePathname } from "next/navigation";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "@finbackoffice/site-core";
import { ICalendarDateState } from "components/pages/sport/calendar/Calendar";
import { useDate } from "hooks";
import { RouteNames } from "utils/constants";
import styles from "./live-header.module.sass";

type IProps = {
    from: number;
    to: number;
    currentDate: ICalendarDateState | null;
    onChange: (val: number) => void;
};

type IDatesState = {
    label: {
        day: string;
        month: string;
    };
    value: number;
};

const CalendarDateSelector: FC<IProps> = ({ from, to, currentDate, onChange }) => {
    const [dates, setDates] = useState<IDatesState[]>([]);
    const { t } = useTranslation();
    const pathname = usePathname();
    const { differenceInDays, addDays, formatDate } = useDate();
    const isCalendar = useMemo(() => pathname?.includes(RouteNames.CALENDAR), [pathname]);

    useEffect(() => {
        const getDates = (): IDatesState[] => {
            const result: IDatesState[] = [];

            for (let i = 0; i < -differenceInDays(from, to); ++i) {
                const day = addDays(from, i);

                result.push({
                    value: day.valueOf(),
                    label: {
                        day: `${
                            day.valueOf() <= Date.now()
                                ? t("header_dateSelectorLiveToday")
                                : formatDate(day, "dd", false)
                        }`,
                        month: `${day.valueOf() <= Date.now() ? "" : formatDate(day, "MMM")}`,
                    },
                });
            }

            return result;
        };

        setDates(getDates());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to]);

    useEffect(() => {
        if (dates.length && isCalendar && !currentDate) {
            onChange((dates[0] as any).value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCalendar, dates]);

    return (
        <>
            {dates.map((date: IDatesState) => (
                <span
                    onClick={() => onChange(date.value)}
                    key={date.value}
                    className={classNames(
                        styles.navItem,
                        currentDate &&
                            date.value >= currentDate?.from &&
                            date.value <= currentDate?.to &&
                            pathname?.includes(RouteNames.CALENDAR) &&
                            styles.active,
                    )}>
                    {date.label.day}
                    {date.label.month && <span>{date.label.month}</span>}
                </span>
            ))}
        </>
    );
};

export default CalendarDateSelector;
