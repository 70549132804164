"use client";

import { FC, PropsWithChildren } from "react";
import { usePathnameWithLocal } from "@finbackoffice/site-core";
import Footer from "components/footer/Footer";
import MainContent from "components/MainContent";
import { PrematchFeedSubscription } from "../sportsbook/prematch/PrematchLayout";

export const CommonLayout: FC<PropsWithChildren> = ({ children }) => {
    const { pathnameValues } = usePathnameWithLocal();
    const content = (
        <MainContent>
            {children}
            <Footer />
        </MainContent>
    );

    const isHome = pathnameValues.length === 0;

    if (isHome) {
        return <PrematchFeedSubscription>{content}</PrematchFeedSubscription>;
    } else {
        return content;
    }
};
