"use client";

import { TranslationScopes } from "@finbackoffice/enums";
import { FC, PropsWithChildren, ReactElement, useContext } from "react";
import classNames from "classnames";
import { ModalsContextV2 } from "@finbackoffice/site-core";
import { Svg } from "components/base/svg/Svg";
import Translate from "components/base/translate/Translate";
import styles from "./account-bets-layout.module.sass";

type IProps = {
    headingIcon?: ReactElement;
    showBackButton?: boolean;
    wrapperClassname?: string;
};

const AccountBetsHeader: FC<IProps & PropsWithChildren> = ({
    wrapperClassname,
    headingIcon,
    showBackButton = true,
    children,
}) => {
    const { accountModalRef } = useContext(ModalsContextV2);

    return (
        <div className={classNames(styles.header, wrapperClassname)}>
            <div
                onClick={() => {
                    if (showBackButton) {
                        accountModalRef.current?.open();
                    }
                }}>
                {showBackButton && (
                    <i className={styles.back} data-testid="account-go-back-button" />
                )}

                <span>
                    {headingIcon || (
                        <Svg
                            src="/common/desktop/base-icons/account-my-bets.svg"
                            wrapper="span"
                            className="svg-icon"
                        />
                    )}

                    <Translate
                        tid="account_bets_betsHistory"
                        namespace={TranslationScopes.UserAccount}
                    />
                </span>
            </div>
            <div className={styles.myBetsTabs}>{children}</div>
        </div>
    );
};

export default AccountBetsHeader;
