"use client";

import { ICasinoFavoriteResponse } from "@finbackoffice/clientbff-client";
import { FC, MutableRefObject, createContext, useCallback, useMemo, useRef } from "react";
import Compact from "./layouts/compact/Compact";

type IProps = {
    favoriteGame: ICasinoFavoriteResponse;
    setSelectedGame: (
        game: ICasinoFavoriteResponse | null,
        modalPosition?: number,
        modalType?: "left" | "right",
    ) => void;
    isActive: boolean;
};

type ICircleContextContext = Omit<IProps, "setSelectedGame"> & {
    circleRef: MutableRefObject<HTMLDivElement | null>;
    handleClick: () => void;
};

export const CircleContext = createContext<ICircleContextContext>(null as any);

const Circle: FC<IProps> = ({ favoriteGame, setSelectedGame, isActive }) => {
    const circleRef = useRef<HTMLDivElement | null>(null);

    const handleClick = useCallback(() => {
        if (!isActive) {
            const element = circleRef.current;
            const rect = element?.getBoundingClientRect();
            const elementLeft = rect ? rect.left : 0;
            const elementWidth = rect ? rect.width : 0;
            const modalType = window.innerWidth / 2 > elementLeft ? "left" : "right";
            setSelectedGame(
                favoriteGame,
                modalType === "left"
                    ? elementLeft - elementWidth * 1.5
                    : elementLeft - window.innerWidth * 0.5 + elementWidth * 2.5,
                modalType,
            );
        } else {
            setSelectedGame(null);
        }
    }, [favoriteGame, isActive, setSelectedGame]);

    const value = useMemo(
        () => ({
            handleClick,
            favoriteGame,
            isActive,
            circleRef,
        }),
        [favoriteGame, handleClick, isActive],
    );

    return (
        <CircleContext.Provider value={value}>
            <Compact />
        </CircleContext.Provider>
    );
};

export default Circle;
