"use client";

import { FC, RefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { IExchangeRatePair, IWalletResponse } from "@finbackoffice/clientbff-client";
import { Currency, WalletType, isCryptoCurrency } from "@finbackoffice/enums";
import { CurrencyShortNames } from "@finbackoffice/fe-core";
import {
    AuthContext,
    BetSlipContextV2, // refactor
    ConfigContextV2, // refactor
    ExchangeRatesContextV2, // refactor
    UserAccountContextV2, // refactor
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import { SkinVersions } from "@finbackoffice/site-server-core";
import Button from "components/base/button/Button";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import { useSubscribeWalletsById } from "hooks";
import Loading from "components/base/loading/Loading";
import Translate from "components/base/translate/Translate";
import { CurrencyFormatter } from "components/base/currency-formater/CurrencyFormater";
import { Chars } from "utils/strings";
import { Svg } from "components/base/svg/Svg";
import styles from "../header.module.sass";

type IProps = {
    rates: IExchangeRatePair[];
    wallet: Partial<IWalletResponse>;
    fxCurrency: Currency | "";
    currentWallet: Partial<IWalletResponse>;
    token: string;
};

const WalletItem: FC<IProps> = ({ wallet, rates, fxCurrency, currentWallet, token }) => {
    const { setSelectedWallet } = useContext(UserAccountContextV2);
    const { currency, balance, type } = wallet;
    const { siteDefaultCurrency } = useContext(ConfigContextV2);
    const { setBetError } = useContext(BetSlipContextV2);
    const isDefaultCurrencyBalance =
        currency === siteDefaultCurrency && type === WalletType.Balance;

    const rate = useMemo(() => {
        const result = parseFloat(rates.find((pair) => pair.toCurrency === fxCurrency)?.rate ?? "");
        return !Number.isNaN(result) ? result : 0;
    }, [rates, fxCurrency]);

    const handleWalletChange = () => {
        setSelectedWallet(wallet);

        setBetError(null);
    };

    if (!currency) {
        return null;
    }

    return (
        <li
            data-testid={`currencies-dropdown-choice-${currency}`}
            className={classNames(
                currency === currentWallet.currency &&
                    type === currentWallet.type &&
                    styles.selected,
                currentWallet.type === WalletType.Bonus && styles.bonus,
            )}
            onClick={handleWalletChange}>
            <Svg
                src={`/common/currencies/${currency.toLowerCase()}.svg`}
                wrapper="span"
                className={classNames(
                    "svg-icon crypto-currency",
                    type === WalletType.Bonus && styles.typeBonus,
                )}
            />
            <div>
                <span>
                    {CurrencyShortNames[currency] || currency}
                    {balance && (
                        <CurrencyFormatter currency={currency} amount={balance} withCode={false} />
                    )}
                </span>
                <div className={styles.currency}>
                    {type === WalletType.Bonus && (
                        <Svg
                            src="/common/desktop/base-icons/bonus-label.svg"
                            wrapper="span"
                            className={styles.bonusLabel}
                        />
                    )}
                    {!isDefaultCurrencyBalance &&
                        (isCryptoCurrency(currency) || type === WalletType.Bonus) && (
                            <span className={`${type === WalletType.Bonus && styles.bonusWallet}`}>
                                <Translate
                                    tid={`currencies_${
                                        type === WalletType.Bonus ? "bonus" : currency.toLowerCase()
                                    }`}
                                />
                            </span>
                        )}
                    {!isDefaultCurrencyBalance && isCryptoCurrency(currency) && (
                        <span>
                            {token ? (
                                currency !== fxCurrency ? (
                                    rate ? (
                                        <>
                                            <i />
                                            {rate && balance && (
                                                <CurrencyFormatter
                                                    currency={fxCurrency}
                                                    amount={(parseFloat(balance) * rate).toString()}
                                                    withCode={false}
                                                    withSymbol
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <Loading />
                                    )
                                ) : null
                            ) : (
                                <CurrencyFormatter
                                    currency={Currency.USD}
                                    amount="0"
                                    withCode={false}
                                    withSymbol
                                />
                            )}
                        </span>
                    )}
                </div>
            </div>
        </li>
    );
};

const SubscribeOnWalletUpdates: FC = () => {
    useSubscribeWalletsById();

    return null;
};

const WalletSelector: FC = () => {
    const { siteConfigReady, siteSupportedWallets } = useContext(ConfigContextV2);
    const { exchangeRates, userCurrencyExchangeRate, fxCurrencyExchangeRate } =
        useContext(ExchangeRatesContextV2);
    const { currentWallet, currentBalance, userCurrency } = useContext(UserAccountContextV2);
    const { userToken } = useContext(AuthContext);
    const [openDropdown, setOpenDropdown] = useState(false);
    const dropdownRef: RefObject<HTMLDivElement> = useRef(null);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const fiatEnabled = COMMON_SITE_CONFIGS.wallet.fiat;
    const fxCurrency = fiatEnabled ? userCurrency : COMMON_SITE_CONFIGS.wallet.fxCurrency;
    const exchangeRate = fiatEnabled ? userCurrencyExchangeRate : fxCurrencyExchangeRate;
    const hideSelector = !userToken && COMMON_SITE_CONFIGS.skinVersion === SkinVersions.Betmidas;

    useEffect(() => {
        const outsideClickHandler = (event: any) => {
            if (dropdownRef && !dropdownRef.current?.contains(event.target)) {
                setOpenDropdown(false);
            }
        };

        if (openDropdown) {
            document.addEventListener("mousedown", outsideClickHandler);
        } else {
            document.removeEventListener("mousedown", outsideClickHandler);
        }

        return () => {
            document.removeEventListener("mousedown", outsideClickHandler);
        };
    }, [openDropdown]);

    return (
        <>
            {userToken && <SubscribeOnWalletUpdates />}
            {!hideSelector && (
                <div
                    className={styles.customDropdown}
                    ref={dropdownRef}
                    data-testid="currencies-dropdown-toggle"
                    onClick={() => currentWallet?.currency && setOpenDropdown(!openDropdown)}>
                    <Button
                        type="button"
                        variant="custom"
                        className={classNames(openDropdown && styles.opened)}>
                        {siteConfigReady && !!currentWallet?.currency ? (
                            <>
                                {currentWallet.type === WalletType.Bonus && (
                                    <Svg
                                        src="/common/desktop/base-icons/bonus-label.svg"
                                        wrapper="span"
                                        className={styles.bonusLabel}
                                    />
                                )}
                                <Svg
                                    src={`/common/currencies/${currentWallet?.currency?.toLowerCase()}.svg`}
                                    wrapper="span"
                                    className={classNames(
                                        "svg-icon crypto-currency",
                                        currentWallet.type === WalletType.Bonus && styles.typeBonus,
                                    )}
                                />
                                <div>
                                    <CurrencyFormatter
                                        currency={currentWallet?.currency}
                                        amount={currentBalance}
                                        withCode={false}
                                    />
                                    {isCryptoCurrency(currentWallet?.currency) &&
                                        (userToken ? (
                                            currentWallet?.currency !== fxCurrency ? (
                                                exchangeRate ? (
                                                    <span>
                                                        ≈{Chars.nbsp}
                                                        {exchangeRate && currentBalance && (
                                                            <CurrencyFormatter
                                                                currency={fxCurrency}
                                                                amount={(
                                                                    parseFloat(currentBalance) *
                                                                    exchangeRate
                                                                ).toString()}
                                                                withCode={false}
                                                                withSymbol
                                                            />
                                                        )}
                                                    </span>
                                                ) : (
                                                    <Loading wrapperClassName={styles.loader} />
                                                )
                                            ) : null
                                        ) : (
                                            <div>
                                                <CurrencyFormatter
                                                    currency={Currency.USD}
                                                    amount="0"
                                                    withCode={false}
                                                    withSymbol
                                                />
                                            </div>
                                        ))}
                                </div>
                            </>
                        ) : (
                            <Loading wrapperClassName={styles.loader} />
                        )}
                    </Button>
                    {currentWallet && openDropdown && (
                        <FadeInAnimation>
                            <ul className={styles.dropdownList}>
                                {siteSupportedWallets.map(
                                    (wallet: Partial<IWalletResponse>, index) => (
                                        <WalletItem
                                            key={index}
                                            rates={
                                                exchangeRates?.[wallet.currency as Currency] || []
                                            }
                                            wallet={wallet}
                                            fxCurrency={fxCurrency}
                                            currentWallet={currentWallet}
                                            token={userToken || ""}
                                        />
                                    ),
                                )}
                            </ul>
                        </FadeInAnimation>
                    )}
                </div>
            )}
        </>
    );
};

export default WalletSelector;
