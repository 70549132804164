"use client";

import React, {
    FC,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import classNames from "classnames";
import { INotificationResponse } from "@finbackoffice/clientbff-client";
import Link from "next/link";
import { HOUR_IN_MS, formatAppNameText } from "@finbackoffice/fe-core";
import { Settings } from "react-slick";
import {
    BrowserStorageContext,
    BrowserStorageIdsEnum,
    SiteNotificationsContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import SlickSlider from "components/base/slick-slider/SlickSlider";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import Img from "components/base/img/Img";
import { ModalTypes } from "utils/constants";
import styles from "./site-notifications.module.sass";

type IProps = {
    notifications: INotificationResponse[];
};

const SiteNotifications: FC<IProps> = ({ notifications }) => {
    const { get } = useContext(BrowserStorageContext);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const notificationsMemo = useMemo(() => {
        const storageNotifications = get(BrowserStorageIdsEnum.SITE_NOTIFICATIONS) ?? {};

        return notifications.filter(
            (item) =>
                !storageNotifications[item.id] ||
                Date.now() - storageNotifications[item.id]?.last_seen_timestamp >
                    COMMON_SITE_CONFIGS.siteNotificationShowInterval * HOUR_IN_MS,
        );
    }, [get, notifications, COMMON_SITE_CONFIGS.siteNotificationShowInterval]);
    const ref: RefObject<IModalForwardRefProps> = useRef(null);
    const { updateStorageNotifications } = useContext(SiteNotificationsContext);
    const [viewed, setViewed] = useState<number[]>([]);
    const [title, setTitle] = useState<string>(notificationsMemo[0]?.subject);

    useEffect(() => {
        if (notificationsMemo.length > 0) {
            ref.current?.open();
        }
    }, [notificationsMemo]);

    const handleBeforeChange = useCallback(
        (_currentSlide: number, nextSlide: number) => {
            setTitle(notificationsMemo[nextSlide].subject);
        },
        [notificationsMemo],
    );

    const handleAfterChange = useCallback(
        (currentSlide: number) => {
            const id = notificationsMemo[currentSlide].id;
            if (!viewed.includes(id)) {
                setViewed([...viewed, id]);
            }
        },
        [notificationsMemo, viewed],
    );

    const handleOnInit = useCallback(() => {
        const id = notificationsMemo[0].id;
        if (!viewed.includes(id)) {
            setViewed([...viewed, id]);
        }
    }, [notificationsMemo, viewed]);

    const sliderSettings: Settings = useMemo(
        () => ({
            infinite: notificationsMemo.length > 1,
            autoplay: true,
            arrows: false,
            autoplaySpeed: 13000,
            dots: notificationsMemo.length > 1,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: handleBeforeChange,
            afterChange: handleAfterChange,
            onInit: handleOnInit,
        }),
        [handleAfterChange, handleBeforeChange, handleOnInit, notificationsMemo.length],
    );

    const handleOnClose = useCallback(() => {
        updateStorageNotifications(viewed);
    }, [updateStorageNotifications, viewed]);

    return (
        <Modal
            ref={ref}
            styleClass={styles.siteNotificationsModal}
            type={ModalTypes.SITE_NOTIFICATIONS}
            animateVariant="opacity"
            closeButton
            priority
            mask={false}
            onClose={handleOnClose}>
            <section className={styles.siteNotifications} data-testid="site-notifications-modal">
                <div className={styles.header}>
                    <Link href="/">
                        <Img
                            source={`${ASSETS_URL}/${formatAppNameText(
                                COMMON_SITE_CONFIGS.appName,
                            )}/desktop/logo.svg`}
                            alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                            width={0}
                            height={0}
                            style={{ width: "100%", height: "auto" }}
                        />
                    </Link>
                </div>
                <div className={styles.content}>
                    <div className={styles.title}>
                        <span>{title}</span>
                    </div>
                    <SlickSlider settings={sliderSettings}>
                        {notificationsMemo.map((item) => (
                            <div
                                key={item.id}
                                className={classNames("slider-animate-zoom", styles.item)}>
                                <p
                                    className="icon-notification"
                                    dangerouslySetInnerHTML={{ __html: item.body }}
                                />
                            </div>
                        ))}
                    </SlickSlider>
                </div>
            </section>
        </Modal>
    );
};

export default SiteNotifications;
