"use client";

import { FC, useContext } from "react";
import { CasinoGameTag } from "@finbackoffice/enums";
import Modal from "components/base/modal/Modal";
import { CasinoFavoritesPanelContext } from "../../CasinoFavoritesPanel";
import Thumbnail from "../../thumbnail/Thumbnail";
import { ModalTypes } from "utils/constants";
import styles from "./compact.module.sass";

const Compact: FC = () => {
    const { selectedGame, modalRef, modalType, setSelectedGame } = useContext(
        CasinoFavoritesPanelContext,
    );

    return (
        <Modal
            ref={modalRef}
            type={ModalTypes.CASINO_FAVORITE_THUMBNAIL}
            animateVariant="opacity"
            styleClass={styles.dropdownModal}
            onClose={() => {
                setSelectedGame(null);
            }}>
            <Thumbnail
                selectedGame={selectedGame}
                modalType={modalType}
                hideTag={[CasinoGameTag.Popular]}
            />
        </Modal>
    );
};

export default Compact;
