"use client";

import { FC, useContext, useMemo } from "react";
import { I18nextProvider } from "@finbackoffice/site-core";
import { useParams } from "next/navigation";
import { CommonTranslationsContext } from "contexts";
import { CommonLayout } from "components/layout/common/CommonLayout";
import { SportLiveLayout } from "./SportLiveLayout";

export const SportLiveLayoutContainer: FC<{
    children: React.ReactNode;
    translations: Record<string, any>;
}> = ({ children, translations }) => {
    const params = useParams();
    const translationsContext = useContext(CommonTranslationsContext);

    const value = useMemo(
        () => ({
            ...translationsContext.translations,
            ...translations,
        }),
        [translations, translationsContext.translations],
    );

    const getLayout = useMemo(() => {
        if (params?.liveGameRoutes && params?.liveGameRoutes?.length > 0) {
            return <CommonLayout>{children}</CommonLayout>;
        } else {
            return <SportLiveLayout>{children}</SportLiveLayout>;
        }
    }, [params, children]);

    return (
        <I18nextProvider translations={value}>
            <>{getLayout}</>
        </I18nextProvider>
    );
};
