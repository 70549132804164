"use client";

import { FC, useContext, useMemo } from "react";
import { I18nextProvider } from "@finbackoffice/site-core";
import { CommonTranslationsContext } from "contexts";
import Header from "components/header/Header";
import Menu from "components/menu/Menu";

export const MainLayout: FC<{
    children: React.ReactNode;
    translations: Record<string, any>;
}> = ({ children, translations }) => {
    const translationsContext = useContext(CommonTranslationsContext);

    const value = useMemo(
        () => ({
            ...translationsContext.translations,
            ...translations,
        }),
        [translations, translationsContext.translations],
    );

    return (
        <I18nextProvider translations={value}>
            <Header />
            {children}
            <Menu />
        </I18nextProvider>
    );
};
