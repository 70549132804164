"use client";

import { FC } from "react";
import AccountBetsLayout from "components/layout/bets/AccountBetsLayout";

export const AccountBetsLayoutContainer: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    return <AccountBetsLayout>{children}</AccountBetsLayout>;
};
