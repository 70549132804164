"use client";

import { FC, useContext } from "react";
import { ModalsContextV2 } from "@finbackoffice/site-core"; // refactor
import Modal from "../../base/modal/Modal";
import { ModalTypes } from "../../../utils/constants";
import styles from "./verification-kyc.module.sass";
import VerificationKyc from "./VerificationKyc";

const VerificationModal: FC = () => {
    const { verifyModalRef } = useContext(ModalsContextV2);

    return (
        <Modal
            ref={verifyModalRef}
            type={ModalTypes.VERIFICATION_KYC}
            styleClass={styles.verificationModal}
            priority={true}
            animateVariant="bottom">
            <VerificationKyc />
        </Modal>
    );
};

export default VerificationModal;
