"use client";

import { FC, useContext, useMemo } from "react";
import {
    afroSignupValidationSchema,
    ConfigContextV2, // refactor
    signupValidationSchema,
    useSiteConfig,
} from "@finbackoffice/site-core";
import { ISiteConfigLayouts, SignupLayoutEnum } from "@finbackoffice/fe-core";
import dynamic from "next/dynamic";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Loading from "components/base/loading/Loading";
import PreloadComponent from "components/base/preload-component/PreloadComponent";
import styles from "./signup.module.sass";

const SignupDetailsForm = dynamic(() => import("./details/SignupDetailsForm"), {
    loading: () => <PreloadComponent style={{ height: 564, margin: 0, border: 0 }} />,
    ssr: false,
});

const CpfSignup = dynamic(() => import("./cpf/CpfSignup"), {
    loading: () => <PreloadComponent style={{ height: 272, margin: 0, border: 0 }} />,
    ssr: false,
});

const CpfSignupOneStep = dynamic(() => import("./cpf-one-step/CpfSignupOneStep"), {
    loading: () => <PreloadComponent style={{ height: 414, margin: 0, border: 0 }} />,
    ssr: false,
});

const CpfSignupKyc = dynamic(() => import("./cpf-kyc/CpfSignupKyc"), {
    loading: () => <PreloadComponent style={{ height: 414, margin: 0, border: 0 }} />,
    ssr: false,
});

const Signup: FC = () => {
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const { siteConfigReady } = useContext(ConfigContextV2); // refactor

    const renderContent = useMemo(() => {
        switch (siteLayoutsConfig._signup.type) {
            case SignupLayoutEnum.CPFSignup:
                return (
                    <ErrorBoundary name={CpfSignup.name}>
                        <CpfSignup />
                    </ErrorBoundary>
                );
            case SignupLayoutEnum.CPFSignup1Step:
                return (
                    <ErrorBoundary name={CpfSignupOneStep.name}>
                        <CpfSignupOneStep />
                    </ErrorBoundary>
                );
            case SignupLayoutEnum.CPFSignupKyc:
                return (
                    <ErrorBoundary name={CpfSignupOneStep.name}>
                        <CpfSignupKyc />
                    </ErrorBoundary>
                );
            case SignupLayoutEnum.Afro:
                return (
                    <ErrorBoundary name={SignupDetailsForm.name}>
                        <SignupDetailsForm validationSchema={afroSignupValidationSchema} />
                    </ErrorBoundary>
                );
            default:
                return (
                    <ErrorBoundary name={SignupDetailsForm.name}>
                        <SignupDetailsForm validationSchema={signupValidationSchema} />
                    </ErrorBoundary>
                );
        }
    }, [siteLayoutsConfig._signup]);

    if (!siteConfigReady) {
        return <Loading />;
    }

    return <section className={styles.signup}>{renderContent}</section>;
};

export default Signup;
