"use client";

import classNames from "classnames";
import { FC, useState, useLayoutEffect, RefObject } from "react";
import FadeInAnimation from "../fade-in/FadeInAnimation";
import Translate from "../translate/Translate";
import styles from "./tooltip.module.sass";

interface IProps {
    targetRef: RefObject<any>;
    message: string;
    condition?: string;
    variant?: string;
}

const Tooltip: FC<IProps> = ({ targetRef, message, variant, condition }) => {
    const [visible, setVisible] = useState<boolean>(false);

    useLayoutEffect(() => {
        const instance = targetRef.current;
        const onFocusHandler = () => {
            if (condition === "empty") {
                if (!instance.value) {
                    setVisible(true);
                }
            } else {
                setVisible(true);
            }
        };

        const onBlurHandler = () => {
            setVisible(false);
        };

        const onInputHandler = () => {
            if (condition === "empty") {
                if (instance.value) {
                    setVisible(false);
                } else {
                    setVisible(true);
                }
            }
        };

        if (instance) {
            instance.addEventListener("focus", onFocusHandler);
            instance.addEventListener("blur", onBlurHandler);
            instance.addEventListener("input", onInputHandler);
        }

        return () => {
            if (instance) {
                instance.removeEventListener("focus", onFocusHandler);
                instance.removeEventListener("blur", onBlurHandler);
                instance.removeEventListener("input", onInputHandler);
            }
        };
    }, [condition, targetRef]);

    if (!visible) return null;

    return (
        <FadeInAnimation>
            <Translate tid={message} dangerous>
                <div className={classNames(styles.infoTooltip, variant && styles[variant])} />
            </Translate>
        </FadeInAnimation>
    );
};

export default Tooltip;
