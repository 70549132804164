import { ICasinoFavoriteResponse, ICasinoGame } from "@finbackoffice/clientbff-client";
import classNames from "classnames";
import { FC } from "react";
import { CasinoGameTag } from "@finbackoffice/enums";
import CasinoGameItem from "../../game-item/CasinoGameItem";
import styles from "./thumbnail.module.sass";

type IProps = {
    selectedGame: ICasinoGame | ICasinoFavoriteResponse | null;
    modalType?: string;
    hideTag?: CasinoGameTag[];
    showName?: boolean;
};

const Thumbnail: FC<IProps> = ({ selectedGame, modalType, hideTag, showName }) => {
    return (
        selectedGame && (
            <>
                <CasinoGameItem game={selectedGame} hideTag={hideTag} showName={showName} />
                <span className={classNames(styles.arrow, modalType && styles[modalType])} />
            </>
        )
    );
};

export default Thumbnail;
