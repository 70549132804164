"use client";

import { FC, PropsWithChildren, useContext } from "react";
import { AuthContext } from "@finbackoffice/site-core";
import MainContent from "components/MainContent";

export const AccountCommonLayout: FC<PropsWithChildren> = ({ children }) => {
    const { isUserLoggedIn } = useContext(AuthContext);

    if (!isUserLoggedIn) {
        return children;
    }

    return <MainContent variant="accountView">{children}</MainContent>;
};
