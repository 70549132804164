import { FC, PropsWithChildren, useMemo } from "react";
import {
    DropdownOption,
    useBonusPagesConfig,
    usePathnameWithLocal,
    useTranslation,
} from "@finbackoffice/site-core";
import { BonusPagesEnum } from "@finbackoffice/fe-core";
import { TranslationScopes } from "@finbackoffice/enums";
import { useRouter } from "next/navigation";
import CustomSelect from "components/base/custom-select/CustomSelect";
import Footer from "components/footer/Footer";
import MainContent from "components/MainContent";
import Translate from "components/base/translate/Translate";
import { RouteNames, RouterQuery } from "utils/constants";
import styles from "./bonuses-layout.module.sass";

const BonusesLayout: FC<PropsWithChildren> = ({ children }) => {
    const router = useRouter();
    const { pathnameValues, path } = usePathnameWithLocal();
    const { t } = useTranslation(TranslationScopes.Bonuses);
    const enabledBonusesConfig = useBonusPagesConfig();
    const isPromotionsPage = path === `/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}`;

    const options = useMemo(() => {
        const result: DropdownOption[] = [];

        enabledBonusesConfig.forEach((config) => {
            switch (config.name) {
                case BonusPagesEnum.Referral:
                    if (config.enable) {
                        result.push({
                            value: RouteNames.BONUS_REFERRAL,
                            label: t("bonuses_menu_referral"),
                            svgSrc: "/common/desktop/bonuses/bonuses-referral.svg",
                        });
                    }

                    break;
                case BonusPagesEnum.Cashback:
                    if (config.enable) {
                        result.push({
                            value: RouteNames.CASHBACK,
                            label: t("bonuses_menu_cashback"),
                            svgSrc: "/common/desktop/bonuses/bonuses-cashback.svg",
                        });
                    }
                    break;
                case BonusPagesEnum.Casino:
                    if (config.enable) {
                        result.push({
                            value: `${RouteNames.PROMOTIONS}/${RouterQuery.Casino}`,
                            label: t("bonuses_menu_casino"),
                            svgSrc: "/common/desktop/bonuses/bonuses-promotions.svg",
                        });
                    }
                    break;
                case BonusPagesEnum.Casino2:
                    if (config.enable) {
                        result.push({
                            value: `${RouteNames.PROMOTIONS}/${RouterQuery.Casino2}`,
                            label: t("bonuses_menu_casino_2"),
                            svgSrc: "/common/desktop/bonuses/bonuses-promotions.svg",
                        });
                    }
                    break;
                case BonusPagesEnum.Promo2:
                    if (config.enable) {
                        result.push({
                            value: `${RouteNames.PROMOTIONS}/${RouterQuery.Promo2}`,
                            label: t("bonuses_menu_promo_2"),
                            svgSrc: "/common/desktop/bonuses/bonuses-promotions.svg",
                        });
                    }
                    break;
                case BonusPagesEnum.Promo3:
                    if (config.enable) {
                        result.push({
                            value: `${RouteNames.PROMOTIONS}/${RouterQuery.Promo3}`,
                            label: t("bonuses_menu_promo_3"),
                            svgSrc: "/common/desktop/bonuses/bonuses-promotions.svg",
                        });
                    }
                    break;
                case BonusPagesEnum.Promo4:
                    if (config.enable) {
                        result.push({
                            value: `${RouteNames.PROMOTIONS}/${RouterQuery.Promo4}`,
                            label: t("bonuses_menu_promo_4"),
                            svgSrc: "/common/desktop/bonuses/bonuses-promotions.svg",
                        });
                    }
                    break;
                case BonusPagesEnum.Promo5:
                    if (config.enable) {
                        result.push({
                            value: `${RouteNames.PROMOTIONS}/${RouterQuery.Promo5}`,
                            label: t("bonuses_menu_promo_5"),
                            svgSrc: "/common/desktop/bonuses/bonuses-promotions.svg",
                        });
                    }
                    break;
                case BonusPagesEnum.Promo6:
                    if (config.enable) {
                        result.push({
                            value: `${RouteNames.PROMOTIONS}/${RouterQuery.Promo6}`,
                            label: t("bonuses_menu_promo_6"),
                            svgSrc: "/common/desktop/bonuses/bonuses-promotions.svg",
                        });
                    }
                    break;
                case BonusPagesEnum.Aviator:
                    if (config.enable) {
                        result.push({
                            value: `${RouteNames.PROMOTIONS}/${RouterQuery.Aviator}`,
                            label: t("bonuses_menu_promo_aviator"),
                            svgSrc: "/common/desktop/bonuses/bonuses-sr_sr-aviator.svg",
                        });
                    }
                    break;
                default:
                    break;
            }
        });

        return result;
    }, [enabledBonusesConfig, t]);

    const getSelected = useMemo(() => {
        const paths = pathnameValues;
        if (paths.includes(RouteNames.PROMOTIONS) && paths.length === 3) {
            return `${RouteNames.PROMOTIONS}/${paths[2]}`;
        }

        return paths[1];
    }, [pathnameValues]);

    return (
        <MainContent>
            <div className={styles.bonusesHeader}>
                <h1>
                    <Translate tid="bonuses_heading" namespace={TranslationScopes.Bonuses} />
                </h1>
                {!isPromotionsPage && (
                    <CustomSelect
                        data={options}
                        selected={getSelected}
                        onChange={(val) => router.push(`/${RouteNames.BONUSES}/${val}`)}
                        styleClass={styles.dropdown}
                        variant="dropdown"
                        renderItem={(item: any) => (
                            <span className={styles.listItem}>{item.label}</span>
                        )}
                    />
                )}
            </div>
            {children}
            <Footer />
        </MainContent>
    );
};

export default BonusesLayout;
