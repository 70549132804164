"use client";

import { createContext, FC, useContext, useMemo } from "react";
import { FeedContext } from "@finbackoffice/site-core";
import { useParams } from "next/navigation";
import { ICategoryFeedResponse, ISearchOptions } from "@finbackoffice/websocket-client";
import { FeedTypeEnum, useSocketStorage } from "hooks";
import LiveLayout from "components/layout/sportsbook/live/LiveLayout";

type ILiveLayoutContext = {
    activeSportId?: number;
    getCategories: (sportId: number, options?: ISearchOptions) => ICategoryFeedResponse[];
    ready: boolean;
};

export const LiveLayoutContext = createContext<ILiveLayoutContext>(null as any);

export const SportLiveLayout: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const params = useParams();
    const { getCategories, getSportBySlug, connected } = useSocketStorage(FeedTypeEnum.live);
    const { liveFeed, liveIsLoading, hasLiveFeedGames } = useContext(FeedContext);
    const ready = connected && !liveIsLoading && hasLiveFeedGames;

    const activeSportId = useMemo(
        () => (ready ? (getSportBySlug(params?.sport as string)?.id ?? liveFeed[0].id) : undefined),
        [getSportBySlug, ready, params?.sport, liveFeed],
    );

    const value = useMemo(
        () => ({
            activeSportId,
            getCategories,
            ready,
        }),
        [activeSportId, getCategories, ready],
    );

    return (
        <LiveLayoutContext.Provider value={value}>
            <LiveLayout sport={liveFeed} activeSportId={activeSportId}>
                {children}
            </LiveLayout>
        </LiveLayoutContext.Provider>
    );
};
