"use client";

import { usePathname, useRouter } from "next/navigation"; // refactor
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import {
    DropdownOption,
    usePoliciesPagesConfig,
    useRuntimeConfig,
    useTranslation,
} from "@finbackoffice/site-core";
import { TranslationScopes } from "@finbackoffice/enums";
import { IPolicyPagesConfig, PolicyPagesEnum } from "@finbackoffice/fe-core";
import CustomSelect from "components/base/custom-select/CustomSelect";
import Footer from "components/footer/Footer";
import MainContent from "components/MainContent";
import Translate from "components/base/translate/Translate";
import Img from "components/base/img/Img";
import { RouteNames } from "utils/constants";
import styles from "./policies-layout.module.sass";

const PoliciesLayout: FC<PropsWithChildren> = ({ children }) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const router = useRouter();
    const pathname = usePathname();
    const { t } = useTranslation(TranslationScopes.Policies);
    const enabledPoliciesConfig = usePoliciesPagesConfig();

    const policiesOptionsMemo = useMemo(() => {
        const result: DropdownOption[] = [];

        enabledPoliciesConfig.forEach((config: IPolicyPagesConfig) => {
            switch (config.name) {
                case PolicyPagesEnum.Privacy:
                    result.push({
                        value: RouteNames.PRIVACY,
                        label: t("policies_menu_privacy"),
                        svgSrc: "/common/desktop/base-icons/policies-privacy.svg",
                    });
                    break;
                case PolicyPagesEnum.Terms:
                    result.push({
                        value: RouteNames.TERMS,
                        label: t("policies_menu_terms"),
                        svgSrc: "/common/desktop/base-icons/policies-terms.svg",
                    });
                    break;
                case PolicyPagesEnum.Sports:
                    result.push({
                        value: RouteNames.SPORTSBOOK,
                        label: t("policies_menu_sportsbook"),
                        svgSrc: "/common/desktop/base-icons/policies-sportsbook.svg",
                    });
                    break;
                case PolicyPagesEnum.Casino:
                    result.push({
                        value: RouteNames.CASINO,
                        label: t("policies_menu_casino"),
                        svgSrc: "/common/desktop/base-icons/policies-casino.svg",
                    });
                    break;
                case PolicyPagesEnum.Payments:
                    result.push({
                        value: RouteNames.PAYMENTS,
                        label: t("policies_menu_payments"),
                        svgSrc: "/common/desktop/base-icons/policies-payments.svg",
                    });
                    break;
                case PolicyPagesEnum.ResponsibleGaming:
                    result.push({
                        value: RouteNames.RESPONSIBLE_GAMING,
                        label: t("policies_menu_responsible_gaming"),
                        svgSrc: "/common/desktop/base-icons/policies-responsible-gaming.svg",
                    });
                    break;
                case PolicyPagesEnum.Cookies:
                    result.push({
                        value: RouteNames.COOKIES,
                        label: t("policies_menu_cookies"),
                        svgSrc: "/common/desktop/base-icons/policies-cookies.svg",
                    });
                    break;
                case PolicyPagesEnum.SelfExclusion:
                    result.push({
                        value: RouteNames.SELF_EXCLUSION,
                        label: t("policies_menu_self_exclusion"),
                        svgSrc: "/common/desktop/base-icons/policies-self-exclusion.svg",
                    });
                    break;
                case PolicyPagesEnum.DisputeResolution:
                    result.push({
                        value: RouteNames.DISPUTE_RESOLUTION,
                        label: t("policies_menu_dispute_resolution"),
                        svgSrc: "/common/desktop/base-icons/policies-dispute-resolution.svg",
                    });
                    break;
                case PolicyPagesEnum.AntiMoneyLaundering:
                    result.push({
                        value: RouteNames.ANTI_MONEY_LAUNDERING,
                        label: t("policies_menu_anti_money_laundering"),
                        svgSrc: "/common/desktop/base-icons/policies-anti-money-laundering.svg",
                    });
                    break;
                case PolicyPagesEnum.FairnessRngTesting:
                    result.push({
                        value: RouteNames.FAIRNESS_RNG_TESTING,
                        label: t("policies_menu_fairness_rng_testing"),
                        svgSrc: "/common/desktop/base-icons/policies-fairness-rng-testing.svg",
                    });
                    break;
                case PolicyPagesEnum.Kyc:
                    result.push({
                        value: RouteNames.KYC,
                        label: t("policies_menu_kyc"),
                        svgSrc: "/common/desktop/base-icons/policies-kyc.svg",
                    });
                    break;
                default:
                    break;
            }
        });

        return result;
    }, [enabledPoliciesConfig, t]);

    const getSelected = useCallback(() => {
        const paths = pathname?.split("/");
        const parentRouteIndex = paths?.indexOf(RouteNames.POLICIES) || 0;
        return paths?.[parentRouteIndex + 1] || "";
    }, [pathname]);

    return (
        <MainContent>
            <div className={styles.header}>
                <Img
                    source={`${ASSETS_URL}/common/desktop/base-icons/policies.svg`}
                    alt="Policies"
                    width={20}
                    height={22}
                />
                <h1>
                    <Translate tid="policies_heading" namespace={TranslationScopes.Policies} />
                </h1>
                <CustomSelect
                    data={policiesOptionsMemo}
                    selected={getSelected()} // refactor
                    onChange={(val) => router.push(`/${RouteNames.POLICIES}/${val}`)}
                    styleClass={styles.dropdown}
                    variant="dropdown"
                />
            </div>
            {children}
            <Footer />
        </MainContent>
    );
};

export default PoliciesLayout;
