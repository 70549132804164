"use client";

import { FC, useContext, useMemo } from "react";
import classNames from "classnames";
import Button from "components/base/button/Button";
import { useDate } from "hooks";
import { SportResultsContext } from "contexts";
import Translate from "components/base/translate/Translate";
import styles from "./results-header.module.sass";

const ResultsHeader: FC = () => {
    const { formatDate } = useDate();
    const { setActiveDate, days, activeDate } = useContext(SportResultsContext);

    const daysMemo = useMemo(
        () =>
            days.map((value) => {
                return {
                    value,
                    day: value.getDate(),
                    month: formatDate(value, "MMM"),
                };
            }),
        [days, formatDate],
    );

    return (
        <div className={styles.sportResultsHeader}>
            <h3>
                <Translate tid="pageName_results" />
            </h3>
            <nav>
                {daysMemo.map((val) => (
                    <Button
                        key={val.day}
                        type="button"
                        className={classNames([{ [styles.active]: activeDate === val.value }])}
                        onClick={() => setActiveDate(val.value)}>
                        <span>{val.day}</span>
                        <span>{val.month}</span>
                    </Button>
                ))}
            </nav>
        </div>
    );
};

export default ResultsHeader;
