import { RuntimeConfigContext } from "@finbackoffice/site-core";
import { CasinoGameTypeEnum } from "@finbackoffice/fe-core";
import classNames from "classnames";
import { FC, useContext, useMemo } from "react";
import Img from "components/base/img/Img";
import Video from "components/base/video/Video";
import { getCasinoGameImgSrc } from "utils/helpers";
import { CircleContext } from "../../Circle";
import styles from "./compact.module.sass";

const Compact: FC = () => {
    const runtimeConfig = useContext(RuntimeConfigContext);
    const { handleClick, isActive, circleRef, favoriteGame } = useContext(CircleContext);
    const gameImgSrc = useMemo(
        () =>
            getCasinoGameImgSrc(
                runtimeConfig,
                favoriteGame.game_id,
                favoriteGame.type as CasinoGameTypeEnum,
                favoriteGame.tags,
            ),
        [runtimeConfig, favoriteGame.game_id, favoriteGame.tags, favoriteGame.type],
    );
    const isVideo = gameImgSrc.includes("mp4");

    return (
        <div
            className={classNames(styles.favoriteItemCompact, isActive && styles.selected)}
            onClick={handleClick}
            ref={circleRef}>
            {isVideo ? (
                <Video src={gameImgSrc} />
            ) : (
                <Img
                    source={gameImgSrc}
                    fallbackSrc={favoriteGame.image_portrait}
                    alt={`${favoriteGame.type} ${favoriteGame.name}`}
                    title={`${favoriteGame.type} ${favoriteGame.name}`}
                    width={96}
                    height={64}
                />
            )}
        </div>
    );
};

export default Compact;
